.slick-prev,
.slick-next {
  z-index: 1;
  width: 40px;  /* Increase the width */
  height: 40px; /* Increase the height */
}

.slick-prev {
  left: -50px;
}

.slick-next {
  right: -50px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px; /* Increase the font size */
  color: #0a2342 !important; /* Set the color to the navy blue used in the site */
  opacity: 1; /* Make sure the arrows are fully opaque */
}

.slick-dots li button:before {
  color: #0a2342 !important;
  font-size: 12px !important;
}

.slick-dots li.slick-active button:before {
  color: #0a2342 !important;
  opacity: 1;
}

@media (max-width: 1024px) {
  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }
}